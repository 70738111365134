<template>
    <div class="o-section o-section--related">
        <div class="o-container">
            <div v-if="$mq === 'small' || $mq === 'medium'">
                <span class="c-txt c-txt--meta c-txt--upper c-txt--bold u-color--primary">Artikel teilen</span>
                <action-bar :bardata="bardata" :postID="postID"></action-bar>
            </div>

            <span class="c-txt c-txt--meta c-txt--upper c-txt--bold u-color--primary">Mehr dazu Lesen</span>
            <div class="c-tags">
                <router-link class="c-txt c-txt--upper c-txt--bold u-color--light-blue c-tag" v-for="(tag, i) in tags" :to="`/archive/${ tag.slug }`" :key="i">{{ `#${tag.title}` }}</router-link>
            </div>
        </div>

        <post-grid :posts="related" cssClass="c-post-grid--related"></post-grid>
    </div>
</template>

<script>
    import PostGrid from '@/components/partials/PostGrid'
    import actionBar from '@/components/partials/ActionBar'

    export default {
        name: 'Related',
        props: {
            tags: Array,
            related: Array,
            bardata: Array,
            postID: Number
        },

        components: {
            PostGrid,
            actionBar
        }
    }
</script>

<style scoped lang="scss">
    .c-tags {
        margin-top: 1.5rem;
    }

    .c-tag {
        @include fp(font-size, 20, 24);
        @extend %blockFocus;
        text-decoration: none;
        display: inline-block;
        hyphens: auto;

        &:after {
            display: none;
        }

        &:not(:last-child) {
            @include fp(margin-right, 20, 24);
        }
    }
</style>