<template>
    <div class="c-intro__author c-author o-container" :class="{ 'c-author--has-img' : author.author_image }">
        <div class="c-author__inner">
            <div class="c-author__text">{{ `Von ${ authorName }` }}</div>
            <div class="c-author__img" v-if="author.author_image"
                 :style="{ backgroundImage: `url(${ author.author_image.sizes.medium.src[0] })` }"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'author',
        props: {
            author: Object
        },


        computed: {
            authorName() {
                const author_data = this.author;
                return `${author_data.first_name} ${author_data.last_name}`
            }
        },
    }
</script>